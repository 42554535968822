"use client";

import { useEffect, useRef, useState } from "react";
import type { Dispatch, SetStateAction } from "react";

type UseBodyScrollLock = {
  readonly setBodyLocked: Dispatch<SetStateAction<boolean>>;
};

/**
 * Inspired by: {@link https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/}
 */
export function useBodyScrollLock(): UseBodyScrollLock {
  const [isBodyLocked, setBodyLocked] = useState<boolean>(false);
  const scrollPosition = useRef(0);

  useEffect(() => {
    if (!isBodyLocked) {
      return;
    }

    scrollPosition.current = window.scrollY;

    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollPosition.current}px`;
    document.body.style.width = "100%";

    return function cleanUp() {
      document.body.style.removeProperty("overflow");
      document.body.style.removeProperty("position");
      document.body.style.removeProperty("top");
      document.body.style.removeProperty("width");

      window.scrollTo(0, scrollPosition.current);
    };
  }, [isBodyLocked]);

  return {
    setBodyLocked,
  };
}
