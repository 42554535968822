"use client";

import clsx from "clsx";
import { usePathname } from "next/navigation";
import type { ReactNode } from "react";
import { Link } from "../Link/Link.component";
import type { LinkProps } from "../Link/Link.component";
type FrontPageLinkProps = {
  readonly children: ReactNode;
};
export function FrontPageLink({
  children,
  ...linkProps
}: Omit<LinkProps, "href"> & FrontPageLinkProps) {
  const pathName = usePathname();
  const {
    className,
    position,
    testId,
    ...rest
  } = linkProps;
  return pathName === "/" ? <a href="/" {...rest} className={clsx("hyphens-manual break-words", className)} data-k5a-pos={position} data-testid={testId} data-sentry-component="FrontPageLink" data-sentry-source-file="FrontPageLink.component.tsx">
      {children}
    </a> : <Link href="/" {...linkProps} data-sentry-element="Link" data-sentry-component="FrontPageLink" data-sentry-source-file="FrontPageLink.component.tsx">
      {children}
    </Link>;
}