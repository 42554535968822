"use client";

import { Hamburger } from "@/components/Icons/components/Hamburger/Hamburger.component";
import { useSite } from "@/contexts/site/site.context";
import { useKilkayaClickEvent } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import { useTracking } from "@/hooks/tracking/use-tracking";
import { useTrackingVersion } from "@/hooks/tracking/use-tracking-version";
import { usePathname } from "next/navigation";
import type { Dispatch, SetStateAction } from "react";
import { ToolbarMenuDenseItem } from "./ToolbarMenuDenseItem.component";
type ToolbarMenuButtonToggleMenuProps = {
  readonly isNavOpen: boolean;
  readonly onToggle: Dispatch<SetStateAction<boolean>>;
  readonly strokeWidth?: 1 | 2;
};
export function ToolbarMenuButtonToggleMenu({
  isNavOpen,
  onToggle,
  strokeWidth
}: ToolbarMenuButtonToggleMenuProps) {
  const pathname = usePathname();
  const {
    domain
  } = useSite();
  const {
    trackClick: trackClickV2
  } = useTracking();
  const {
    trackClick: trackClickV1
  } = useKilkayaClickEvent();
  const trackingVersion = useTrackingVersion();
  function trialClick() {
    if (trackingVersion === "v2") {
      trackClickV2({
        position: "mm-btn",
        toUrl: `https://${domain}${pathname}`,
        url: `https://${domain}${pathname}`
      });
    }
    if (trackingVersion === "v1") {
      trackClickV1({
        position: "mm-btn",
        toUrl: `https://${domain}${pathname}`,
        url: `https://${domain}${pathname}`
      });
    }
  }
  return <button className="toolbar-menu-item" onKeyDown={event => {
    if (event.key === "Enter") {
      onToggle(previous => !previous);
      trialClick();
    }
  }} onMouseDown={() => {
    onToggle(previous => !previous);
    trialClick();
  }} type="button" data-sentry-component="ToolbarMenuButtonToggleMenu" data-sentry-source-file="ToolbarMenuButtonToggleMenu.component.tsx">
      <ToolbarMenuDenseItem label={{
      da: "Menu",
      de: "Menu",
      en: "Menu",
      no: "Meny",
      sv: "Meny"
    }} data-sentry-element="ToolbarMenuDenseItem" data-sentry-source-file="ToolbarMenuButtonToggleMenu.component.tsx">
        <Hamburger className="h-3 w-3" isNavOpen={isNavOpen} strokeWidth={strokeWidth} data-sentry-element="Hamburger" data-sentry-source-file="ToolbarMenuButtonToggleMenu.component.tsx" />
      </ToolbarMenuDenseItem>
    </button>;
}