"use client";

import type { ShopLinks } from "@/app/_api/shop/_models/shop-links.model";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";
const ShopLinksContext = createContext<undefined | ShopLinks>(undefined);
export function useShopLinks(): ShopLinks {
  const shopLinks = useContext(ShopLinksContext);
  if (shopLinks === undefined) {
    throw new Error("A ShopLinksProvider must be present in the component hierarchy.");
  }
  return shopLinks;
}
type ShopLinksProviderProps = {
  readonly children: ReactNode;
  readonly shopLinks: ShopLinks;
};
export function ShopLinksProvider({
  children,
  shopLinks
}: ShopLinksProviderProps) {
  return <ShopLinksContext.Provider value={shopLinks} data-sentry-element="unknown" data-sentry-component="ShopLinksProvider" data-sentry-source-file="ShopLinks.context.tsx">
      {children}
    </ShopLinksContext.Provider>;
}