"use client";

import { useHeader } from "@/contexts/header/header.context";
import { useBodyScrollLock } from "@/hooks/use-body-scroll-lock.hook";
import { useScrollDirection } from "@/hooks/use-scroll-direction.hook";
import { useViewportBreakpoint } from "@/hooks/use-viewport-breakpoint.hook";
import { compareBreakpoints } from "@/utilities/compare-breakpoints";
import { clsx } from "clsx";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { headerTransitionClassNames } from "./common-transition-class-names";
import { AppBar } from "./components/AppBar/AppBar.component";
import { Masthead } from "./components/Masthead/Masthead.component";
import { NavigationDrawer } from "./components/NavigationDrawer/NavigationDrawer.component";
import type { HeaderModel } from "./models/header-model.types";
type HeaderProps = {
  readonly className?: string;
  readonly model: HeaderModel;
  readonly theme?: "primary" | "white";
};
export const headerScrollYDeltaThreshold = 16;
export function Header({
  className,
  model,
  theme = "white"
}: HeaderProps) {
  const {
    isVisible: isHeaderVisible,
    setIsVisible: setIsHeaderVisible
  } = useHeader();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const {
    isScrollingDown
  } = useScrollDirection({
    scrollYDeltaThreshold: headerScrollYDeltaThreshold
  });
  const {
    isScrollingUp
  } = useScrollDirection({
    scrollYDeltaThreshold: headerScrollYDeltaThreshold
  });
  const {
    setBodyLocked
  } = useBodyScrollLock();
  const mastheadRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(mastheadRef, {
    threshold: 0
  });
  const isMastheadInView: boolean = entry?.isIntersecting ?? false;
  const {
    currentBreakpoint: breakpoint
  } = useViewportBreakpoint();
  const isLg = compareBreakpoints(breakpoint, ">=", "lg");
  useEffect(() => {
    const closeNavigation = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault(); // Otherwise Firefox will minimize window instead :P
        setIsNavOpen(false);
      }
    };
    window.addEventListener("keydown", closeNavigation, false);
    return function cleanUp() {
      window.removeEventListener("keydown", closeNavigation, false);
    };
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: `setBodyLocked` originates from `useState`; hence, it is guaranteed to be stable.
  useEffect(() => setBodyLocked(isNavOpen), [isNavOpen]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: `setIsNavOpen` originates from `useState`; hence, it is guaranteed to be stable.
  useEffect(() => {
    setIsNavOpen(false);
  }, [pathname, searchParams]);
  useEffect(() => {
    if (isLg) {
      setIsNavOpen(false);
    }
  }, [isLg]);
  useEffect(() => setIsHeaderVisible(isScrollingUp && !isMastheadInView), [isScrollingUp, isMastheadInView, setIsHeaderVisible]);
  return <header className={clsx(className, "fixed left-0 top-0 z-10 w-full lg:relative print:static", isNavOpen && "h-full overflow-y-auto")} data-sentry-component="Header" data-sentry-source-file="Header.component.tsx">
      <div className="fixed z-20 w-full lg:static print:static" ref={mastheadRef}>
        <Masthead isCollapsed={isScrollingDown} isNavOpen={isNavOpen} model={model} setIsNavOpen={setIsNavOpen} theme={theme} data-sentry-element="Masthead" data-sentry-source-file="Header.component.tsx" />
      </div>

      <AppBar className={clsx("top-0 hidden transform-gpu ease-in-out lg:fixed lg:flex print:hidden", headerTransitionClassNames, isHeaderVisible ? "lg:translate-y-0" : "lg:-translate-y-27")} theme={theme} data-sentry-element="AppBar" data-sentry-source-file="Header.component.tsx" />

      {/* Navigational drawer */}
      <aside className={clsx("absolute left-0 top-0 flex min-h-svh w-full overflow-hidden bg-white ease-out lg:hidden print:hidden", headerTransitionClassNames, isScrollingDown ? "pt-6" : "pt-9", !isNavOpen && "pointer-events-none translate-x-full")}>
        <NavigationDrawer items={model.menuItems} data-sentry-element="NavigationDrawer" data-sentry-source-file="Header.component.tsx" />
      </aside>
    </header>;
}