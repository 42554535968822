import type { HeaderModel } from "@/components/Header/models/header-model.types";
import clsx from "clsx";
import type React from "react";
import DrawerList from "./_components/DrawerList.component";
import ManageAccountWrapper from "./_components/ManageAccountWrapper.component";
type NavigationDrawerProps = {
  readonly children?: React.ReactNode;
  readonly className?: string;
  readonly items: ReadonlyArray<HeaderModel.MenuItem>;
};
export function NavigationDrawer({
  className,
  items
}: NavigationDrawerProps) {
  const sectionItems = items.flatMap((menuItem: HeaderModel.MenuItem) => {
    if (menuItem.children.length > 0) {
      return menuItem.children;
    }
    return menuItem;
  });
  return <nav className={clsx(className, "flex w-full flex-col justify-between")} data-sentry-component="NavigationDrawer" data-sentry-source-file="NavigationDrawer.component.tsx">
      <div className="flex grow flex-col justify-between">
        <ManageAccountWrapper data-sentry-element="ManageAccountWrapper" data-sentry-source-file="NavigationDrawer.component.tsx">
          <DrawerList items={sectionItems} data-sentry-element="DrawerList" data-sentry-source-file="NavigationDrawer.component.tsx" />
        </ManageAccountWrapper>
      </div>
    </nav>;
}