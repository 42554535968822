import { clsx } from "clsx";
type HamburgerProps = {
  readonly className?: string;
  readonly isNavOpen?: boolean;
  readonly strokeWidth?: 1 | 2;
};
export function Hamburger({
  className,
  isNavOpen = false,
  strokeWidth = 1
}: HamburgerProps) {
  const strokeWidthClass = strokeWidth === 1 ? "h-px" : "h-[2px]";
  return <div className={clsx(className, "relative flex items-center")} data-sentry-component="Hamburger" data-sentry-source-file="Hamburger.component.tsx">
      <span aria-hidden="true" className={clsx(strokeWidthClass, "absolute w-full transform-gpu rounded-sm bg-current transition duration-300 motion-reduce:transition-none", isNavOpen ? "-rotate-45" : "-translate-y-0.875")} />
      <span aria-hidden="true" className={clsx(strokeWidthClass, "absolute right-0 w-2/3 rounded-sm bg-current transition-opacity duration-300 motion-reduce:transition-none", isNavOpen ? "opacity-0" : "opacity-100")} />
      <span aria-hidden="true" className={clsx(strokeWidthClass, "absolute w-full transform-gpu rounded-sm bg-current transition duration-300 motion-reduce:transition-none", isNavOpen ? "rotate-45" : "translate-y-0.875")} />
    </div>;
}