"use client";

import { headerTransitionClassNames } from "@/components/Header/common-transition-class-names";
import type { HeaderModel } from "@/components/Header/models/header-model.types";
import { OutlineChevronDownIcon } from "@/components/Icons/components/HeroIcons/OutlineChevronDownIcon.component";
import { Link } from "@/components/Link/Link.component";
import { Menu, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import { usePathname } from "next/navigation";
import { Fragment } from "react";
type NavigationDropdownProps = {
  readonly menuItem: HeaderModel.MenuItem;
  readonly theme?: "primary" | "white";
};
export function NavigationDropdown({
  menuItem,
  theme = "primary"
}: NavigationDropdownProps) {
  const pathname = usePathname();
  return <Menu data-sentry-element="Menu" data-sentry-component="NavigationDropdown" data-sentry-source-file="NavigationDropdown.component.tsx">
      {({
      open
    }: {
      readonly open: boolean;
    }) => <>
          <Menu.Button className={clsx("flex h-full items-center justify-center gap-x-0.5 text-sm uppercase leading-none transition-colors", theme === "primary" && "hover:text-primary", theme === "white" && "hover:text-secondary")}>
            {menuItem.name}
            <OutlineChevronDownIcon className={clsx("h-2 w-2 transform-gpu ease-out", headerTransitionClassNames, open && "rotate-180")} />
          </Menu.Button>
          <Transition as={Fragment} enter="transition motion-reduce:transition-none ease-out origin-top-left duration-200" enterFrom="opacity-0 transform-gpu scale-90" enterTo="opacity-100 transform-gpu scale-100" leave="transition motion-reduce:transition-none origin-top-left ease-in duration-100" leaveFrom="opacity-100 transform-gpu scale-100" leaveTo="opacity-0 transform-gpu scale-90">
            <Menu.Items as="ul" className="absolute left-0 top-full min-w-xs border border-silver bg-white">
              {menuItem.children.map(({
            key,
            name,
            url
          }, index) => {
            const isActive = pathname.includes(url);
            return <li className="border-b border-silver last:border-b-0" data-k5a-pos={`nav-dd${index + 1}`} key={key}>
                    <Menu.Item>
                      {({
                  active
                }: {
                  readonly active: boolean;
                }) => <Link className={clsx("flex h-6 w-full items-center justify-between px-2 py-1 text-sm font-theme-bold uppercase transition-colors", isActive && "bg-primary text-white", !isActive && "text-charcoal hover:bg-white hover:text-primary", !isActive && active && "text-primary")} href={url} openInNewTab="determineFromSource">
                          {name}
                        </Link>}
                    </Menu.Item>
                  </li>;
          })}
            </Menu.Items>
          </Transition>
        </>}
    </Menu>;
}