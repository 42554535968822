import { Link } from "@/components/Link/Link.component";
import { Spinner } from "@/components/Spinner/Spinner.component";
import { clsx } from "clsx";
import { regularButtonCommonClasses, regularButtonVariantClasses } from "../regular-button-class-names";
import type { CommonButtonProps } from "../regular-button.types";
type LinkButtonProps = CommonButtonProps & {
  readonly openInNewTab?: "determineFromSource" | boolean;
  readonly href: string;
  readonly isPending?: boolean;
  readonly testId?: string;
};
export function RegularLinkButton({
  children,
  className,
  disabled = false,
  href,
  isPending = false,
  openInNewTab,
  position,
  variant = "primary",
  testId = "regular-link-button"
}: LinkButtonProps) {
  return <Link className={clsx(className, regularButtonCommonClasses, regularButtonVariantClasses[variant], (isPending || disabled) && "cursor-not-allowed border-silver bg-silver hover:border-silver hover:bg-silver", (isPending || disabled) && variant === "outline" && "bg-transparent text-silver hover:bg-transparent")} disabled={isPending || disabled} href={href} openInNewTab={openInNewTab} position={position} testId={testId} data-sentry-element="Link" data-sentry-component="RegularLinkButton" data-sentry-source-file="RegularLinkButton.component.tsx">
      <span className={isPending ? "invisible" : "visible"}>{children}</span>

      {isPending ? <Spinner className="absolute h-2 w-2 fill-primary text-whisper group-disabled:fill-silver" /> : null}
    </Link>;
}