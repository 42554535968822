import clsx from "clsx";
import type { ReactNode } from "react";
type ToolbarMenuListItemProps = {
  readonly children: ReactNode;
  readonly theme?: "dark" | "light";
};
export function ToolbarMenuListItem({
  children,
  theme = "dark"
}: ToolbarMenuListItemProps) {
  return <li className={clsx(theme === "dark" && "hover:text-primary", theme === "light" && "hover:text-secondary")} data-sentry-component="ToolbarMenuListItem" data-sentry-source-file="ToolbarMenuListItem.component.tsx">
      {children}
    </li>;
}